import { PROD_URL } from "api/config";

const URL_POST_IMAGE = `${PROD_URL}/images`;
const URL_POST_DOCUMENT = `${PROD_URL}/document`;
const URL_POST_TALENTS_DOCUMENT = `${PROD_URL}/talents/update-document`;
const URL_GET_IMAGE = (imageId: string) => `${PROD_URL}/images/${imageId}`;
const URL_GET_IMAGE_DATA = (imageId: string) => `${PROD_URL}/images/${imageId}/data`;
const URL_DELETE_IMAGE = (imageId: string) => `${PROD_URL}/images/${imageId}`;

export {
	URL_POST_IMAGE,
	URL_POST_DOCUMENT,
	URL_POST_TALENTS_DOCUMENT,
	URL_GET_IMAGE,
	URL_GET_IMAGE_DATA,
	URL_DELETE_IMAGE,
};
