import * as React from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { FieldInputProps } from "./typing";

export const FieldInput = (props: FieldInputProps) => {
	const {
		placeholder,
		label,
		type,
		icon,
		min,
		max,
		onChange,
		onBlur,
		className,
		value = "",
		validate,
		sanitizer,
		disabled,
		step = 0,
	} = props;

	const [error, setError] = React.useState("");
	const [hookedValue, setHookedValue] = React.useState(value);

	React.useEffect(() => {
		setHookedValue(value);
	}, [value]);

	return (
		<React.Fragment>
			<div className={`FieldInput ${icon ? "ui left icon input" : ""} ${className}`}>
				<input
					type={type ? type : "text"}
					onChange={(event) => {
						const value = event.target.value;
						const sanitizedValue = sanitizer ? sanitizer(value) : value;
						onChange(sanitizedValue);
						setHookedValue(sanitizedValue);
					}}
					onBlur={(event) => {
						const targetValue = event.target.value;
						const sanitizedValue = sanitizer ? sanitizer(targetValue) : targetValue;
						const validateText = validate(sanitizedValue ? sanitizedValue : "");
						setError(validateText);
						onBlur(sanitizedValue, Boolean(validateText));
						setHookedValue(sanitizedValue);
					}}
					placeholder={placeholder ? placeholder : String(label)}
					min={min}
					max={max}
					value={hookedValue}
					step={step}
					disabled={disabled}
				/>
				{icon && <Icon name={icon} />}
			</div>
			{error && <p className="full-width font-color-error"> {error} </p>}
		</React.Fragment>
	);
};
