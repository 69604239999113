import { fetchImage } from "api/FileAPI";
import imageCompression from "browser-image-compression";
import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import GridColumn from "semantic-ui-react/dist/commonjs/collections/Grid/GridColumn";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import { ImagePickerProps } from "./typing";
import { URL_VINGLET_IMAGE } from "constants/URLs";
import { CookiesUtils } from "@utils";

const DEFAULT_IMAGE = "/default-image.png";

export class ImagePicker extends React.PureComponent<ImagePickerProps> {
	state = {
		uploading: false,
	};
	private inputFile: HTMLInputElement | null = null;

	onSelectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}

		const files = Array.from(event.target.files);
		const { onChange } = this.props;

		this.setState({ uploading: true });
		onChange("temp-name.png", true);

		const compressionOptions = {
			maxSizeMB: 0.5,
			maxWidthOrHeight: 1200,
			useWebWorker: true,
		};

		imageCompression(files[0], compressionOptions)
			.then((compressedFile) => {
				const formData = new FormData();

				formData.append("file", compressedFile);

				const image = fetchImage(formData, compressedFile.type, CookiesUtils.getAccessTokenFromCookie())();

				image.then((data) => {
					onChange(data && data[0], false);
					this.setState({ uploading: false });
				});
			})
			.catch((error) => {
				this.setState({ uploading: false });
			});
	};

	getFullImageURL = (imageSrc: string) => {
		if (imageSrc) {
			if (imageSrc.includes(URL_VINGLET_IMAGE)) {
				return imageSrc;
			}

			return URL_VINGLET_IMAGE + imageSrc;
		}

		return String(DEFAULT_IMAGE);
	};

	render() {
		const { image, basic, children, bordered, className = "", labelTextButton } = this.props;
		const { uploading } = this.state;

		return (
			<Grid
				className={`ImagePicker margin-0-left padding-2-all full-width ${bordered ? "bordered" : ""} ${className}`}
				columns="1"
			>
				<input
					className="hide"
					type="file"
					accept=".jpg,.jpeg,.png"
					ref={(input) => (this.inputFile = input)}
					onChange={(event) => this.onSelectImage(event)}
				/>
				{!basic && (
					<React.Fragment>
						<GridColumn className="image-picker-container">
							<Button
								loading={uploading}
								className="image-picker-button padding-0_5-all"
								onClick={() => this.inputFile?.click()}
							>
								<Image
									circular
									src={this.getFullImageURL(image.src)}
								/>
							</Button>
						</GridColumn>
						<GridColumn>{children}</GridColumn>
					</React.Fragment>
				)}
				<GridColumn>
					<Button
						loading={uploading}
						onClick={() => this.inputFile?.click()}
					>
						{labelTextButton || "Cambiar foto de perfil"}
					</Button>
				</GridColumn>
			</Grid>
		);
	}
}
