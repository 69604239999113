import { FieldDropdownProps } from "components/Forms/Field/FieldDropdown/typing";
import * as React from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

export const FieldDropdown = (props: FieldDropdownProps) => {
	const [error, setError] = React.useState("");
	const { validate, ...dropdownProps } = props;

	return (
		<React.Fragment>
			<Dropdown
				{...dropdownProps}
				onChange={(event, data) => {
					const validations = validate(data.value ? data.value : "");
					setError(validations);
					props.onChange && props.onChange(event, data);
				}}
			/>
			{error && <span className="font-color-error"> {error} </span>}
		</React.Fragment>
	);
};
