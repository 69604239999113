import { ReactNode } from "react";

export enum FieldStyle {
	INPUT,
	INPUT_PASSWORD,
	SELECT,
	CHECKBOX,
	RADIO,
	RANGE,
}

export interface FieldType {
	style: FieldStyle;
	[key: string]: any;
}

export interface FieldProps {
	label?: string | ReactNode;
	type: FieldType;
	onChange?: ([]: any) => void;
	onBlur?: ([]: any) => void;
	className?: string;
	labelClassName?: string;
	fieldClassName?: string;
	required?: boolean;
	validate: (valueToValidate: any) => string;
}
