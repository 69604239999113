import { Translation } from "i18n/config";
import { ValidationMessageTranslation } from "./typing";

export const ValidationsMessageTexts: Translation<ValidationMessageTranslation> = {
	es: {
		checkEmail: "Verifique que el formato del email este bien ingresado.",
		checkPassword: "Verifique que el formato de la contraseña este bien ingresado.",
		checkURL: "Verifique la URL esta bien ingresada.",
		checkMaximumCharacteres: (max: number) => {
			return `Debe tener como maximo ${max} caracteres.`;
		},
		checkMinimumAndMaximumCharacters: (min: number, max: number) => {
			return `Debe tener entre ${min} y ${max} caracteres.`;
		},
		checkTermsAndConditions: "Debes de aceptar los terminos y condiciones.",
		completeFields: "Compruebe que los datos ingresados son correctos.",
		mustBeCompleted: "Este campo debe ser completado.",
	},
	en: {
		checkEmail: "Please check the email format.",
		checkPassword: "Please check the password format.",
		checkURL: "Check that the format of the URL is correct.",
		checkMaximumCharacteres: (max: number) => {
			return `It must be completed and have ${max} characters maximum.`;
		},
		checkMinimumAndMaximumCharacters: (min: number, max: number) => {
			return `It must be completed and have between ${min} and ${max} characters maximum.`;
		},
		checkTermsAndConditions: "You must accept the terms and conditions.",
		completeFields: "Sorry but you need to correctly fill each field.",
		mustBeCompleted: "This field must be completed.",
	},
	ca: {
		checkEmail: "Comproveu que el format de l'email aquest bé ingressat.",
		checkPassword: "Comproveu que el format de la contrasenya aquest bé ingressat.",
		checkURL: "Comproveu la URL aquesta bé ingressada.",
		checkMaximumCharacteres: (max: number) => {
			return `Ha de tenir com a màxim ${max} caràcters.`;
		},
		checkMinimumAndMaximumCharacters: (min: number, max: number) => {
			return `Ha de tenir entre ${min} i ${max} caracteres.`;
		},
		checkTermsAndConditions: "Has d'acceptar els termes i condicions.",
		completeFields: "Comproveu que les dades ingressades són correctes.",
		mustBeCompleted: "Aquest camp ha de ser completat.",
	},
	pt: {
		checkEmail: "Verifique se o formato de e-mail foi inserido corretamente.",
		checkPassword: "Verifique se o formato da senha foi inserido corretamente.",
		checkURL: "Verifique se o URL foi inserido corretamente.",
		checkMaximumCharacteres: (max: number) => {
			return `Deve ter no máximo ${max} caracteres.`;
		},
		checkMinimumAndMaximumCharacters: (min: number, max: number) => {
			return `Deve ter entre ${min} e ${max} caracteres.`;
		},
		checkTermsAndConditions: "Você deve aceitar os Termos e Condições.",
		completeFields: "Verifique se os dados inseridos estão corretos.",
		mustBeCompleted: "Este campo deve ser preenchido.",
	},
};
