import { FieldCheckbox } from "components/Forms/Field/FieldCheckbox/FieldCheckbox";
import { FieldDropdown } from "components/Forms/Field/FieldDropdown/FieldDropdown";
import * as React from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import { FieldInput } from "./FieldInput/FieldInput";
import { FieldProps, FieldStyle } from "./typing";

const FieldFactory = (props: FieldProps) => {
	const { label, type, onChange = function () {}, onBlur = function () {}, fieldClassName = "", validate } = props;
	let field;

	switch (type.style) {
		case FieldStyle.CHECKBOX:
			const { customLabel, value } = type;
			field = (
				<FieldCheckbox
					className={fieldClassName}
					value={value}
					onChange={(event, data) => onChange(data.checked)}
					label={customLabel ? customLabel : label}
					validate={validate}
				/>
			);
			break;
		case FieldStyle.RADIO:
			field = (
				<Radio
					className={fieldClassName}
					toggle
					checked={type.value}
					onChange={(event, data) => onChange(data.checked)}
				/>
			);
			break;
		case FieldStyle.SELECT:
			const { style, ...typeWithoutStyle } = type;

			field = (
				<FieldDropdown
					{...typeWithoutStyle}
					onChange={(event, data) => onChange(data.value)}
					validate={validate}
				/>
			);
			break;
		case FieldStyle.INPUT_PASSWORD:
			field = (
				<FieldInput
					className={fieldClassName}
					icon={type.icon}
					type={"password"}
					onChange={onChange}
					onBlur={onBlur}
					placeholder={type.placeholder}
					label={label}
					value={type.value}
					validate={validate}
					sanitizer={type.sanitizer}
				/>
			);
			break;
		case FieldStyle.INPUT:
			field = (
				<FieldInput
					className={fieldClassName}
					icon={type.icon}
					onChange={onChange}
					onBlur={onBlur}
					placeholder={type.placeholder}
					label={label}
					value={type.value}
					validate={validate}
					sanitizer={type.sanitizer}
					disabled={type.disabled}
				/>
			);
			break;
		case FieldStyle.RANGE:
			const { min, max, step } = type;
			field = (
				<FieldInput
					className={fieldClassName}
					icon={type.icon}
					onChange={onChange}
					onBlur={onBlur}
					placeholder={type.placeholder}
					label={label}
					min={min}
					max={max}
					type="range"
					validate={validate}
					sanitizer={type.sanitizer}
					value={type.value}
					step={step}
				/>
			);
	}

	return field;
};

export class Field extends React.Component<FieldProps> {
	shouldComponentUpdate(nextProps: FieldProps) {
		const { type, label } = this.props;
		return type.value !== nextProps.type.value || label !== nextProps.label;
	}

	render() {
		const { type, className, labelClassName, label, required } = this.props;

		return (
			<Form.Field className={`Field ${className}`}>
				{type.style != FieldStyle.CHECKBOX && (
					<label className={labelClassName}>
						{label}
						{required && <span className="font-color-error"> * </span>}
					</label>
				)}
				{FieldFactory(this.props)}
			</Form.Field>
		);
	}
}
