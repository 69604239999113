import { defaultError, getFileHeaderWithCredentials } from "api/config";
import { URL_POST_TALENTS_DOCUMENT, URL_POST_DOCUMENT, URL_POST_IMAGE } from "./urls";

export function fetchImage(data: FormData, contentType: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_IMAGE, getFileHeaderWithCredentials(data, contentType, accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function fetchDocument(data: FormData, contentType: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_DOCUMENT, getFileHeaderWithCredentials(data, contentType, accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}

export function fetchTalentsDocument(data: FormData, contentType: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_TALENTS_DOCUMENT, getFileHeaderWithCredentials(data, contentType, accessToken))
			.then((response) => response.json())
			.catch(() => defaultError());
	};
}
