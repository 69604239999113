import { Translation } from "i18n/config";
import { ButtonsTranslation } from "./typing";

export const ButtonsTexts: Translation<ButtonsTranslation> = {
	es: {
		removeConnection: "Remover conexión",
		readMore: "Leer Más",
		finish: "Finalizar",
		continue: "Continuar",
		help: "Ayuda",
		back: "Atrás",
		close: "Cerrar",
		next: "Próximo",
		cancel: "Cancelar",
		save: "Guardar",
		edit: "Editar",
		delete: "Eliminar",
		createNew: "Crear Nuevo",
		saveAsDraft: "Guardar como Borrador",
		accept: "Aceptar",
		search: "Buscar",
		apply: "Aplicar",
		goDashboard: "Ir al Dashboard",
		addMentors: "Agregar Mentores",
		sendEmails: "Adjuntar Emails",
		loadMore: "Cargar Más",
		connect: "Conectar",
		connected: "Conectado",
		denied: "Denegado",
		deny: "Denegar",
		pending: "Pendiente",
		viewAll: "Ver todo",
		productsAndSubscriptions: 'Productos y subscripciones'
	},
	en: {
		removeConnection: "Remove from my network",
		readMore: "Read More",
		finish: "Finish",
		continue: "Continue",
		help: "Help",
		back: "Back",
		close: "Close",
		next: "Next",
		cancel: "Cancel",
		save: "Save",
		edit: "Edit",
		delete: "Delete",
		createNew: "Create New",
		saveAsDraft: "Save as Draft",
		accept: "Accept",
		search: "Search",
		apply: "Apply",
		goDashboard: "Go to the dashboard",
		addMentors: "Add Mentors",
		sendEmails: "Attach Emails",
		loadMore: "Load More",
		connect: "Connect",
		connected: "Connected",
		denied: "Denied",
		deny: "Deny",
		pending: "Pending",
		viewAll: "View all",
		productsAndSubscriptions: 'Products and subscriptions'
	},
	ca: {
		removeConnection: "Elimina de la meva xarxa",
		readMore: "Leer Más",
		finish: "Finalitzar",
		continue: "Continuar",
		help: "Ayuda",
		back: "Enrere",
		close: "Tancar",
		next: "Pròxim",
		cancel: "Cancel·lar",
		save: "Guardar",
		edit: "Editar",
		delete: "Eliminar",
		createNew: "Crear Nou",
		saveAsDraft: "Desa com a esborrany",
		accept: "Acceptar",
		search: "Buscar",
		apply: "Aplicar",
		goDashboard: "Anar al Dashboard",
		addMentors: "Agregar Mentores",
		sendEmails: "Adjuntar Emails",
		loadMore: "Cargar Más",
		connect: "Conectar",
		connected: "Connectat",
		denied: "Denegado",
		deny: "Denegar",
		pending: "Pendiente",
		viewAll: "Veure-ho tot",
		productsAndSubscriptions: 'Productes i subscripcions'
	},
	pt: {
		removeConnection: "Remover da minha rede",
		readMore: "Ler mais",
		finish: "Terminar",
		continue: "Continuar",
		help: "Ajuda",
		back: "Voltar",
		close: "Cerrar",
		next: "Próximo",
		cancel: "Cancelar",
		save: "Salvar",
		edit: "Editar",
		delete: "Suprimir",
		createNew: "Crie um novo",
		saveAsDraft: "Salvar como rascunho",
		accept: "Aceitar",
		search: "Procurar",
		apply: "Aplicar",
		goDashboard: "Vá para o painel",
		addMentors: "Adicionar mentores",
		sendEmails: "Anexar e-mails",
		loadMore: "Carregue mais",
		connect: "Conectar",
		connected: "Conectado",
		denied: "Negado",
		deny: "Negar",
		pending: "Pendente",
		viewAll: "Ver tudo",
		productsAndSubscriptions: 'Produtos e assinaturas'
	},
};
