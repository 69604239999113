import { FieldCheckboxProps } from "components/Forms/Field/FieldCheckbox/typing";
import * as React from "react";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import { CheckboxProps } from "semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox";

export const FieldCheckbox = (props: FieldCheckboxProps) => {
	const { className, onChange, label, validate } = props;

	const [value, setValue] = React.useState(props.value);
	const [error, setError] = React.useState("");

	return (
		<React.Fragment>
			<Checkbox
				className={className}
				onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
					onChange(event, data);
				}}
				onClick={() => {
					const inversedValue = !value;
					setError(validate(inversedValue));
					setValue(inversedValue);
				}}
				defaultChecked={value}
				label={label}
			/>
			{error && <p className="full-width font-color-error"> {error} </p>}
		</React.Fragment>
	);
};
